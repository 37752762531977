import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { styled } from '../../../stitches.config';
import { Button } from '../../components/shared/Button';
import { Colors, Hero } from '../../components/shared/Hero';
import { LiveSupport } from '../../components/shared/LiveSupport';
import { SolutionCards } from '../../components/Solution/SolutionCards';
import { Layout } from '../../layout';
import { routes } from '../../routes/routes';
import Download from '../../../static/icons/common/download-solution.svg';
import { SolutionSlider } from '../../components/Solution/SolutionSlider';
import { BreadCrumbs } from '../../components/shared/BreadCrumbs';
import { Seo } from '../../components/shared/Seo';
import { Solution } from '../../types';

type Props = {
	solution: Solution;
};

export const SolutionView: React.FC<Props> = ({ solution }) => {
	const { navigate } = useI18next();
	const { t } = useTranslation();
	const pageColor = solution.attributes.pageColorScheme?.match(/^#([0-9a-f]{3}){1,2}$/i)
		? solution.attributes.pageColorScheme
		: '$accent';

	const richTextDescription = eval('({obj:[' + solution.attributes.heroInfo + ']})').obj[0].blocks;
	const statisticsSlider = solution.attributes.statisticsSlider.data;

	return (
		<Layout>
			<Seo
				description={solution.attributes.shortDescription}
				image={solution.attributes.logo.data.attributes.url}
				title={solution.attributes.heroTitle}
			/>
			<StyledHero
				pic={{ wrap: Image, src: solution.attributes.logo.data.attributes.url }}
				backgroundColor={Colors.transparent}
			>
				<BreadCrumbs root={{ title: 'Home', link: routes.home }} current={solution.attributes.name} />
				<Heading>{solution.attributes.heroTitle}</Heading>
				{richTextDescription.map((el: { data: { text: string } }, i: number) => (
					<HeroDesc
						css={{
							a: {
								color: pageColor
							}
							
						}}
						key={i}
						dangerouslySetInnerHTML={{ __html: `${el.data.text}` }}
					></HeroDesc>
				))}
				<ButtonsWrap>
					<Button onClick={() => navigate(routes.contact.contactForm)} externalColorway={pageColor}>
						{t('eDataGatewayContactUs')}
					</Button>
				</ButtonsWrap>
			</StyledHero>
			{!!solution.attributes.card.length && (
				<CardContainer>
					<SolutionCards backgroundColor={pageColor} cards={solution.attributes.card} />
				</CardContainer>
			)}
			{!!statisticsSlider.length && (
				<SliderSection>
					<SolutionSlider statisticsSlider={statisticsSlider} />
				</SliderSection>
			)}
			{!!solution.attributes.document.length && (
				<DocumentContainer>
					<DocumentTitle>{t('solutions.downloads')}</DocumentTitle>
					<DocumentTable>
						<TableSection>
							<Title>{t('solutions.details')}</Title>
						</TableSection>
						{solution.attributes.document.map((el, i) => (
							<TableSection key={i}>
								<Text>{el.documentName}</Text>
								<DownloadBox href={el.document.data.attributes.url} download>
									<Download />
									<DownloadText>{t('solutions.download')}</DownloadText>
								</DownloadBox>
							</TableSection>
						))}
					</DocumentTable>
				</DocumentContainer>
			)}
			<LiveSupport externalColorway={pageColor} />
		</Layout>
	);
};

const SliderSection = styled('section', {
	padding: '24px 0px 12px 0px'
});

const DocumentContainer = styled('div', {
	maxWidth: 1280,
	margin: '0 auto 40px',
	'@md': {
		margin: '0 auto 88px'
	}
});

const DocumentTitle = styled('h2', {
	color: '$heading',
	textAlign: 'center',
	margin: '0 0 18px',
	fontWeight: 700,
	fontSize: 24,
	lineHeight: '28px',
	'@md': {
		margin: '0 0 32px'
	}
});

const DocumentTable = styled('div', {
	borderRadius: 8,
	border: '1px solid #CFCFCF'
});

const DownloadBox = styled('a', {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	gap: 8,
	cursor: 'pointer',
	padding: '8px 16px',
	borderRadius: 8,
	transition: 'all 300ms ease',
	textDecoration: 'none',
	'&:hover': {
		background: '$secondaryBackground'
	}
});

const Title = styled('h3', {
	color: '$heading',
	margin: '0',
	fontWeight: 700,
	fontSize: 15,
	lineHeight: '20px'
});

const Text = styled('p', {
	color: '$heading',
	fontWeight: 400,
	fontSize: 17,
	lineHeight: '24px',
	margin: '0'
});

const DownloadText = styled('p', {
	color: '$menthol',
	fontWeight: 400,
	fontSize: 17,
	lineHeight: '24px',
	margin: '0'
});

const TableSection = styled('div', {
	padding: '8px 32px',
	borderRadius: '8px 8px 0 0',
	borderBottom: '1px solid #CFCFCF',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	'&:first-of-type': {
		padding: '16px 32px',
		background: '#F4F5F7'
	},
	'&:last-of-type': {
		borderBottom: '0'
	}
});

const CardContainer = styled('div', {
	maxWidth: 1280,
	margin: '0 auto 40px',
	'@md': {
		margin: '0 auto 88px'
	}
});

const StyledHero = styled(Hero, {
	marginBottom: 20
});

const ButtonsWrap = styled('div', {
	display: 'flex',
	flexDirection: 'column',
	marginBottom: 40,
	'@md': {
		flexDirection: 'row',
		marginBottom: 100
	}
});

const Heading = styled('h1', {
	color: '$text',
	fontSize: '32px',
	fontWeight: 700,
	lineHeight: '40px',
	margin: '30px 0 0',

	variants: {
		color: {
			transparent: {
				color: '$text'
			}
		}
	},

	'@md': {
		margin: '0',
		fontSize: '48px',
		lineHeight: '56px',
		color: '$heading',
		maxWidth: '620px'
	}
});

const Image = styled('img', {
	objectFit: 'contain',
	maxWidth: '494px',
	maxHeight: 400,
	width: '90%'
});

const HeroDesc = styled('p', {
	fontSize: '17px',
	fontWeight: 400,
	lineHeight: '24px',
	margin: '12px 0px 12px 0px',
	color: '$text',
	'&:last-of-type': {
		margin: '0 0 32px'
	},
	'@md': {
		color: '$text'
	}
});
